import region from './region';

export const isProd: boolean = process.env.NODE_ENV !== 'development';

export const basePath: string = process.env.PUBLIC_URL || '';
export const PUBLIC_URL: string = process.env.NEXT_PUBLIC_PUBLIC_URL || '';

export const isMockingEnvEnabled =
  process.env.NEXT_PUBLIC_MOCKS_ENABLED && process.env.NEXT_PUBLIC_IA_ENV === 'development';

export const US_BASE_API: string | undefined = process.env.NEXT_PUBLIC_US_API_BASE_URL;
export const BASE_API: string | undefined = process.env.NEXT_PUBLIC_API_BASE_URL;
export const ITEMS_SALES_BASE_URL: string | undefined = process.env.NEXT_PUBLIC_API_ITEMS_SALES_BASE_URL;

export const ITEMS_SALES_PUBLIC_TOKEN: string | undefined = process.env.NEXT_PUBLIC_ITEMS_SALES_PUBLIC_TOKEN;

export const SHOULD_FETCH_ORDER_ITEMS_DETAILS: string | undefined =
  process.env.NEXT_PUBLIC_SHOULD_FETCH_ORDER_ITEMS_DETAILS;

export const SENTRY_URL: string | undefined = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const TERMS_AND_CONDITIONS_URL =
  'https://coffee-growth-296.notion.site/Terms-of-Service-16e2629502664d91bd179219d0cffa2b';

export const GA_TRACKING_ID: string | undefined = process.env.NEXT_PUBLIC_GA_ID;

export const REGIONS: Record<string, string> = {
  US: 'US',
  SG: 'SG',
};

export const REGION: string = process.env.NEXT_PUBLIC_REGION || REGIONS.SG;

export const DISTANCE_UNIT: string = REGION === REGIONS.US ? 'mi' : 'km';

export const MOMOS_WEBSITE_URL = 'https://momos.com/';

export const FACEBOOK_GRAPH_API_ACCESS_TOKEN: string | undefined =
  process.env.NEXT_PUBLIC_FACEBOOK_GRAPH_API_ACCESS_TOKEN;

export const REGION_PHONE = region;

export const SUBSCRIBE_TERMS_AND_CONDITIONS_URL = 'https://www.momos.com/us-privacy-policy';

export const DEFAULT_TS_TEXT = `<p>By checking this box, you agree to the following <a href="about:blank" rel="noopener noreferrer" target="_blank">Terms and Conditions</a> and agree to receive marketing communications from the merchant.</p>`;

export const DATE_FORMAT = 'MMMM DD, YYYY h:mm a'; //January 13, 2024 2:00 pm

export const LOCATION_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  CLOSED_PERMANENTLY: 'CLOSED_PERMANENTLY',
  CLOSED_TEMPORARILY: 'CLOSED_TEMPORARILY',
  DISCONNECTED: 'DISCONNECTED',
  OPEN_FOR_BUSINESS_UNSPECIFIED: 'OPEN_FOR_BUSINESS_UNSPECIFIED',
  LOCATION_UNDER_REVIEW: 'LOCATION_UNDER_REVIEW',
  DUPLICATE: 'DUPLICATE',
  NOT_LIVE: 'NOT_LIVE',
  DISABLED: 'DISABLED',
  SUSPENDED: 'SUSPENDED',
};

export const LOCATION_STATUS_INFO_TAG = {
  OPEN: 'Open Location',
  CLOSED: 'Closed Location',
  CLOSED_PERMANENTLY: 'Permanently Closed Location',
  CLOSED_TEMPORARILY: 'Temporarily Closed Location',
  ACTIVE: 'Active Location',
  DISCONNECTED: 'Disconnected Location',
  OPEN_FOR_BUSINESS_UNSPECIFIED: 'Open For Business',
  LOCATION_UNDER_REVIEW: 'Location Under Review',
  DUPLICATE: 'Duplicate Location',
  NOT_LIVE: 'Location Not Live',
  DISABLED: 'Disabled Location',
  SUSPENDED: 'Suspended Location',
};
